import axios from "axios";
import { useFormik } from "formik";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { FC, Fragment, useState } from "react";
import Balancer from "react-wrap-balancer";
import Colors from "~/constants/colors";
import { isValidEmail } from "~/helpers/form.helpers";
import Trans from "~/types/translationKeys";
import Button from "../Button";
import { Container } from "../Container";
import Icon from "../Icon";
import Input from "../Input";
import Social from "../Social";
import Text from "../Text";
import styles from "./Footer.module.scss";

interface FooterProps {
  isShowMenu?: boolean;
  isShowProductHuntBadge?: boolean;
}

const Footer: FC<FooterProps> = ({
  isShowMenu = true,
  isShowProductHuntBadge = true,
}) => {
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation();

  const { touched, errors, getFieldProps, submitForm, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      validateOnBlur: false,
      initialValues: { email: "" },
      validate: (values) => {
        const err: Record<string, string> = {};
        if (!values.email.trim()) {
          err.email = t(Trans.please_enter_your, {
            name: t(Trans.email).toLowerCase(),
          });
        } else if (!isValidEmail(values.email)) {
          err.email = t(Trans.wrong_email_format);
        }
        return err;
      },
      onSubmit: async (values) => {
        const res = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/v1/newsletters`,
          {
            email: values.email,
          }
        );

        if (res.data) {
          setCompleted(true);
        }
      },
    });
  return (
    <footer className={styles.footer}>
      <Container className="flex flex-col gap-12 lg:gap-10 md:gap-16">
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <Image
              className="object-contain"
              src="/svgs/logo_footer.svg"
              alt="CX Genie logo"
              width={162}
              height={40}
            />
            {isShowProductHuntBadge && (
              <a
                href="https://www.producthunt.com/posts/cx-genie-2?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-cx&#0045;genie&#0045;2"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=486201&theme=light&period=daily"
                  alt="CX&#0032;Genie - Transform&#0032;customer&#0032;support&#0032;with&#0032;no&#0045;code&#0032;AI&#0045;powered&#0032;solutions | Product Hunt"
                  width="200"
                  height="39"
                />
              </a>
            )}
            <div className="grid w-full grid-cols-2 gap-5 md:grid-cols-1 xl:grid-cols-6">
              <div className="hidden flex-col gap-[28px] xl:flex col-span-4">
                <div className="flex flex-col gap-4">
                  <Text type="title-1" color={Colors.GREY_50}>
                    {t(Trans.follow_us)}
                  </Text>
                  <Social />
                </div>
                <div className="flex flex-col gap-4">
                  <Text type="title-1" color={Colors.GREY_50}>
                    {t(Trans.contact)}
                  </Text>
                  <a
                    className="flex items-center gap-2 text-[#C4C9D2]"
                    href="mailto:hello@cxgenie.ai"
                  >
                    <Icon size={20} name="sms" />{" "}
                    <span className="text-sm">hello@cxgenie.ai</span>
                  </a>
                  <div className="flex gap-2 items-start text-[#C4C9D2]">
                    <Icon size={20} name="building-4" />{" "}
                    <span className="text-sm">
                      <Balancer>Promartech Solutions PTE. LTD</Balancer>
                    </span>
                  </div>
                  <div className="flex gap-2 items-start text-[#C4C9D2]">
                    <Icon size={20} name="location" />{" "}
                    <span className="text-sm">
                      <Balancer>
                        {t(
                          Trans[
                            "18_sin_ming_lane_07_13_midview_city_singapore_573960"
                          ]
                        )}
                      </Balancer>
                    </span>
                  </div>
                  <div className="flex gap-2 items-start text-[#C4C9D2]">
                    <Icon size={20} name="location" />{" "}
                    <span className="text-sm">
                      <Balancer>
                        Level 2 - 11 York St, Sydney NSW 2000, Australia
                      </Balancer>
                    </span>
                  </div>
                </div>
              </div>
              {isShowMenu && (
                <div className="flex flex-col gap-8 xl:col-span-2">
                  <a href="/#features">
                    <Text type="title-1">{t(Trans.solutions)}</Text>
                  </a>

                  <a href="/#pricing">
                    <Text type="title-1">{t(Trans.pricing)}</Text>
                  </a>

                  <Link href="/ai-studio">
                    <Text type="title-1">{t(Trans.ai_studio)}</Text>
                  </Link>

                  <Link href="/referral">
                    <Text type="title-1">Referral</Text>
                  </Link>

                  <a href="/resources">
                    <Text type="title-1">{t(Trans.resources)}</Text>
                  </a>
                </div>
              )}
              <div className="flex flex-col gap-8 md:hidden xl:col-span-2">
                <a href="/#faq">
                  <Text type="title-1">{t(Trans.faqs)}</Text>
                </a>

                <Link href="/privacy-policy">
                  <Text type="title-1">{t(Trans.privacy_policy)}</Text>
                </Link>

                <Link href="/terms-of-use">
                  <Text type="title-1">{t(Trans.terms_of_use)}</Text>
                </Link>

                <Link target="_blank" href="https://cxgenie.canny.io">
                  <Text type="title-1">{t(Trans.roadmap)}</Text>
                </Link>
              </div>
            </div>
          </div>

          <div className={styles.right}>
            <div className="flex flex-col gap-2">
              <Text type="headline-5" color={Colors.GREY_50}>
                {t(Trans.join_our_newsletter)}
              </Text>
              <Text color={Colors.GREY_300}>
                {t(
                  Trans.sign_up_for_our_weekly_newsletter_and_stay_ahead_of_the_curve_with_the_latest_ai_innovations_cx_genie_updates_and_much_more
                )}
              </Text>
            </div>
            <div className="flex flex-col items-start gap-3">
              {completed ? (
                <p>
                  {t(
                    Trans.thank_you_for_joining_our_cx_genie_newsletter_please_check_your_email_for_sign_up_confirmation
                  )}
                </p>
              ) : (
                <Fragment>
                  <Input
                    className="!bg-transparent !border-[#5C6169] text-white"
                    containerClassName="w-full"
                    placeholder={t(Trans.enter_your, {
                      name: t(Trans.email_address).toLowerCase(),
                    })}
                    {...getFieldProps("email")}
                    error={touched.email && errors.email}
                  />
                  <Button loading={isSubmitting} onClick={submitForm}>
                    {t(Trans.subscribe)}
                  </Button>
                </Fragment>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-8 lg:gap-6 xl:hidden">
          <div className="flex flex-col gap-5 lg:gap-8">
            <Text type="title-1" color={Colors.GREY_50}>
              {t(Trans.follow_us)}
            </Text>
            <Social />
          </div>
          <div className="flex flex-col gap-5 lg:gap-5">
            <Text type="title-1" color={Colors.GREY_50}>
              {t(Trans.contact)}
            </Text>
            <a
              className="flex items-center gap-2 text-[#A3A9B3]"
              href="mailto:hello@cxgenie.ai"
            >
              <Icon size={24} name="sms" /> hello@cxgenie.ai
            </a>
            <div className="flex items-start gap-2 text-[#A3A9B3]">
              <Icon size={24} name="building-4" />{" "}
              <span>Promartech Solutions PTE. LTD</span>
            </div>
            <div className="flex items-start gap-2 text-[#A3A9B3]">
              <Icon size={24} name="location" />{" "}
              <span>
                {t(
                  Trans["18_sin_ming_lane_07_13_midview_city_singapore_573960"]
                )}
              </span>
            </div>
            <div className="flex items-start gap-2 text-[#A3A9B3]">
              <Icon size={24} name="location" />{" "}
              <span>Level 2 - 11 York St, Sydney NSW 2000, Australia</span>
            </div>
          </div>
        </div>

        <div className="py-3 border-t-[#5C6169] border-t">
          <div className="flex items-center justify-between">
            <Text color={Colors.GREY_400}>
              {t(Trans["2024_cx_genie_all_rights_reserved"])}
            </Text>
            <div className="hidden gap-8 md:flex">
              <a href="/#faq">
                <Text type="title-2" color="white">
                  {t(Trans.faqs)}
                </Text>
              </a>
              <Link href="/privacy-policy">
                <Text type="title-2">{t(Trans.privacy_policy)}</Text>
              </Link>

              <Link href="/terms-of-use">
                <Text type="title-2">{t(Trans.terms_of_use)}</Text>
              </Link>

              <Link target="_blank" href="https://cxgenie.canny.io">
                <Text type="title-2">{t(Trans.roadmap)}</Text>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
