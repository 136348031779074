import Link from "next/link";
import React from "react";

import Icon from "../Icon";

interface SocialProps {
  color?: string;
}

const Social: React.FC<SocialProps> = ({ color }) => {
  return (
    <div className="flex items-center gap-4">
      <Link
        href="https://www.linkedin.com/company/cxgenie"
        target="_blank"
        aria-label="Linkedin"
      >
        <Icon name="linkedin-2" color={color} />
      </Link>
      <Link
        href="https://twitter.com/genie_cx"
        target="_blank"
        aria-label="Twitter"
      >
        <Icon name="x" color={color} />
      </Link>
      <Link
        href="https://www.facebook.com/cxgenie.global"
        target="_blank"
        aria-label="Twitter"
      >
        <Icon name="fb" color={color} />
      </Link>
      <Link
        href="https://www.youtube.com/@CXGenie"
        target="_blank"
        aria-label="Twitter"
      >
        <Icon name="youtube1" color={color} />
      </Link>
      <Link
        href="https://discord.gg/B3heXRJ5dc"
        target="_blank"
        aria-label="Discord"
      >
        <Icon name="discord" color={color} />
      </Link>
    </div>
  );
};

export default Social;
