import { ResourceTypes } from "~/services/resource";
import Trans from "~/types/translationKeys";
import Colors from "./colors";

export const highlightFeatures = [
  {
    title:
      Trans["5_minutes_to_enable_ai_chat_engine_for_both_your_web_and_app"],
    icon: "/svgs/hl_feature_icon_1.svg",
    image: "/svgs/hl_feature_1.svg",
    animation: "/animations/5mins.json",
  },
  {
    title: Trans.hybrid_mode_combine_ai_and_real_human_conversation_at_any_time,
    icon: "/svgs/hl_feature_icon_2.svg",
    image: "/svgs/hl_feature_2.svg",
  },
  {
    title: Trans.auto_study_from_available_data,
    icon: "/svgs/hl_feature_icon_3.svg",
    image: "/svgs/hl_feature_3.svg",
  },
  {
    title:
      Trans.unlimited_free_trial_cancel_anytime_no_commitment_no_data_collected,
    icon: "/svgs/hl_feature_icon_4.svg",
    image: "/svgs/hl_feature_4.svg",
  },
  {
    title: Trans.manage_multi_chat_sessions_labeling,
    icon: "/svgs/hl_feature_icon_5.svg",
    image: "/svgs/hl_feature_5.svg",
  },
];

export const questions = [
  {
    quesiton: Trans.what_is_cx_genie_and_how_does_it_work,
    answer:
      Trans.cx_genie_is_a_platform_designed_to_automate_and_optimize_customer_support_processes_it_uses_natural_language_processing_and_machine_learning_to_provide_automated_efficient_and_personalized_customer_interactions,
  },
  {
    quesiton:
      Trans.what_are_the_key_benefits_of_using_cx_genie_for_our_business,
    answer:
      Trans.cx_genie_maximizes_your_roi_and_offers_a_lot_of_business_benefits_through_improving_customer_engagement_reducing_support_costs_24_7_availability_and_the_ability_to_scale_support_efforts_effortlessly,
  },
  {
    quesiton: Trans.is_cx_genie_suitable_for_businesses_of_all_sizes,
    answer:
      Trans.yes_cx_genie_is_designed_to_cater_to_bussinesses_of_all_sizes_and_categories_from_startups_to_enterprise_level_organizations_whether_you_bring_your_own_code_or_configure_it_in_a_few_clicks_cx_genie_is_easily_modified_to_meet_your_unique_business_needs,
  },
  {
    quesiton:
      Trans.how_does_cx_genie_integrate_with_our_existing_customer_support_tools_and_systems,
    answer:
      Trans.cx_genie_offers_seamless_integration_with_various_customer_support_tools_crm_systems_and_messaging_platforms_our_team_will_work_with_you_to_ensure_a_smooth_integration_process_we_not_only_make_things_easy_on_your_customers_but_set_your_teams_up_for_success_and_keep_your_business_in_sync,
  },
  // {
  //   quesiton: Trans.can_cx_genie_handle_complex_customer_inquiries_and_issues,
  //   answer:
  //     Trans.absolutely_cx_genie_s_ai_is_trained_to_handle_a_wide_range_of_customer_inquiries_from_simple_queries_to_complex_issues_it_continuously_learns_and_improves_its_responses_to_better_assist_your_customers,
  // },
  {
    quesiton: Trans.is_there_a_need_for_human_intervention_when_using_cx_genie,
    answer:
      Trans.cx_genie_can_handle_a_significant_portion_of_customer_inquiries_autonomously_it_s_designed_to_escalate_complex_issues_to_human_agents_when_necessary_ensuring_a_seamless_customer_experience,
  },
  {
    quesiton: Trans.what_kind_of_analytics_and_insights_does_cx_genie_provide,
    answer:
      Trans.cx_genie_offers_comprehensive_analytics_and_insights_into_customer_interactions_you_can_track_customer_satisfaction_response_times_and_other_key_metrics_to_continually_improve_your_support_processes,
  },
  {
    quesiton:
      Trans.is_cx_genie_secure_and_compliant_with_data_protection_regulations,
    answer:
      Trans.yes_cx_genie_prioritizes_data_security_and_compliance_it_adheres_to_industry_standard_security_practices_and_can_be_customized_to_comply_with_data_protection_regulations_specific_to_your_business,
  },
  // {
  //   quesiton: Trans.how_can_we_get_started_with_cx_genie_for_our_business,
  //   answer: Trans.to_get_started_with_cx_genie_for_our_business,
  // },
  {
    quesiton: Trans.is_my_data_secured,
    answer:
      Trans.yes_your_data_is_secured_with_cx_genie_we_dont_store_your_conversation_with_the_ai_your_uploaded_data_links_and_files_are_stored_on_the_cloud_chatgpt_with_their_standard_security_llm_calls_are_made_using_chatgpt_with_their_security_measures,
  },
];

export const referralQuestions = [
  {
    quesiton: Trans.how_do_i_become_a_part_of_the_cx_genie_referral_program,
    answer:
      Trans.to_join_our_referral_program_simply_log_into_your_cx_genie_account,
  },
  {
    quesiton:
      Trans.what_are_the_benefits_of_joining_the_cx_genie_referral_program,
    answer:
      Trans.as_a_cx_genie_referral_you_can_earn_significant_commissions_by_referring_new_customers,
  },
  {
    quesiton: Trans.how_does_the_commission_structure_work,
    answer:
      Trans.for_cx_genie_advocates_you_earn_30_of_the_revenue_from_paid_users,
  },
  {
    quesiton: Trans.how_can_i_track_my_referral_performance_and_earnings,
    answer:
      Trans.you_can_track_your_performance_and_earnings_through_the_dedicated_referral_dashboard,
  },
  {
    quesiton:
      Trans.who_should_i_contact_with_any_questions_about_the_referral_program,
    answer:
      Trans.to_get_support_with_the_referral_program_you_can_reach_out_to_us_on_our_cx_genie_discord,
  },
];

export const customerSupportAgentsOptions = [
  {
    label: "< 10",
    value: "<10",
  },
  {
    label: "10 - 50",
    value: "10-50",
  },
  {
    label: "50+",
    value: ">50",
  },
];

export const privacyContent = [
  {
    title: Trans.introduction,
    list: [
      {
        title: Trans.purpose_of_the_privacy_policy,
        content:
          Trans.this_privacy_policy_outlines_our_commitment_to_protecting_your_privacy_and_explains_how_we_collect_use_disclose_and_safeguard_your_data_when_you_use_cx_genie,
      },
      {
        title: Trans.scope_of_the_privacy_policy,
        content:
          Trans.this_policy_applies_to_all_users_of_cx_genie_including_customers_and_visitors_to_our_website,
      },
      {
        title: Trans.consent_and_acceptance,
        content:
          Trans.by_using_cx_genie_you_agree_to_the_terms_of_this_privacy_policy_and_consent_to_the_collection_processing_and_sharing_of_your_information_as_described_in_herein,
      },
    ],
  },
  {
    title: Trans.information_we_collect,
    list: [
      {
        title: Trans.personal_information,
        content:
          Trans.we_collect_personal_information_such_as_your_name_email_address_and_contact_details_when_you_create_an_account_or_communicate_with_us,
      },
      {
        title: Trans.non_personal_information,
        content:
          Trans.we_may_collect_non_personal_information_such_as_device_information_browser_type_and_usage_data_to_improve_our_services,
      },
      {
        title: Trans.cookies_and_tracking_technologies,
        content:
          Trans.we_use_cookies_and_similar_technologies_to_enhance_your_experience_on_cx_genie_and_for_analytics_and_marketing_purposes_you_can_manage_your_cookie_preferences_through_your_browser_settings,
      },
      {
        title: Trans.third_party_data_collection,
        content:
          Trans.we_may_integrate_with_third_party_services_that_collect_data_independently_please_review_the_privacy_policies_of_these_third_parties_for_more_information,
      },
    ],
  },
  {
    title: Trans.how_we_use_your_information,
    list: [
      {
        title: Trans.providing_services,
        content:
          Trans.we_use_your_information_to_provide_and_improve_cx_genie_s_services_including_customer_support_automation_and_engagement_features,
      },
      {
        title: Trans.customer_support_automation,
        content:
          Trans.cx_genie_uses_ai_to_automate_customer_support_processes_your_interactions_with_our_platform_may_be_analyzed_to_improve_the_quality_of_responses,
      },
      {
        title: Trans.personalization_and_customer_engagement,
        content:
          Trans.we_may_use_your_data_to_personalize_your_experiences_and_engage_with_you_through_emails_notifications_or_in_app_messages,
      },
    ],
  },
  {
    title: Trans.sharing_your_information,
    list: [
      {
        title: Trans.third_party_service_providers,
        content:
          Trans.we_may_share_your_data_with_third_party_service_providers_who_assist_us_in_delivering_and_improving_cx_genie_s_services,
      },
      {
        title: Trans.legal_requirements,
        content:
          Trans.we_may_disclose_your_information_if_required_by_law_such_as_in_response_to_a_subpoena_or_other_legal_process,
      },
      {
        title: Trans.business_transfers,
        content:
          Trans.in_the_event_of_a_merger_acquisition_or_sale_of_assets_your_data_may_be_transferred_to_the_new_entity,
      },
      {
        title: Trans.aggregated_or_deidentified_data,
        content:
          Trans.we_may_share_aggregated_or_de_identified_data_for_analytics_and_research_purposes,
      },
    ],
  },
  {
    title: Trans.your_privacy_choices,
    list: [
      {
        title: Trans.accessing_and_updating_your_information,
        content:
          Trans.we_may_share_your_data_with_third_party_service_providers_who_assist_us_in_delivering_and_improving_cx_genie_s_services,
      },
      {
        title: Trans.communication_preferences,
        content:
          Trans.you_can_manage_your_communication_preferences_by_adjusting_settings_within_your_cx_genie_account_or_by_unsubscribing_from_emails,
      },
      {
        title: Trans.cookies_and_tracking,
        content:
          Trans.you_can_manage_cookies_and_tracking_preferences_through_your_browser_settings,
      },
      {
        title: Trans.do_not_track_signals,
        content: Trans.we_do_not_currently_respond_to_do_not_track_signals,
      },
    ],
  },
  {
    title: Trans.data_security,
    list: [
      {
        title: Trans.security_measures,
        content:
          Trans.we_employ_industry_standard_security_measures_to_protect_your_data,
      },
      {
        title: Trans.data_breach_response,
        content:
          Trans.in_the_event_of_a_data_breach_we_will_notify_affected_users_in_accordance_with_applicable_laws,
      },
    ],
  },
  {
    title: Trans.third_party_links_and_services,
    list: [
      {
        title: Trans.external_websites,
        content:
          Trans.cx_genie_may_contain_links_to_external_websites_we_are_not_responsible_for_the_privacy_practices_or_content_of_these_websites,
      },
      {
        title: Trans.integration_with_third_party_services,
        content:
          Trans.cx_genie_may_integrate_with_third_party_services_your_use_of_these_services_is_subject_to_their_respective_privacy_policies,
      },
    ],
  },
  {
    title: Trans.childrens_privacy,
    list: [
      {
        title: Trans.age_restrictions,
        content:
          Trans.cx_genie_is_not_intended_for_children_under_the_age_of_13_we_do_not_knowingly_collect_personal_information_from_children_under_13,
      },
      {
        title: Trans.parental_consent,
        content:
          Trans.if_you_believe_that_we_have_collected_information_from_a_child_under_13_without_parental_consent_please_contact_us_to_request_removal_of_that_information,
      },
    ],
  },
  {
    title: Trans.international_users,
    list: [
      {
        title: Trans.cross_border_data_transfer,
        content:
          Trans.by_using_cx_genie_you_consent_to_the_transfer_of_your_data_to_the_united_states_and_other_jurisdictions_as_necessary_for_the_purposes_outlined_in_this_privacy_policy,
      },
      {
        title: Trans.eu_users_rights_if_applicable,
        content:
          Trans.if_you_are_an_eu_user_you_may_have_additional_rights_under_the_general_data_protection_regulation_gdpr_please_see_our_separate_gdpr_privacy_notice_for_more_information,
      },
    ],
  },
  {
    title: Trans.changes_to_this_privacy_policy,
    list: [
      {
        title: Trans.notification_of_changes,
        content:
          Trans.we_will_notify_you_of_any_material_changes_to_this_privacy_policy_through_the_cx_genie_platform_or_via_email,
      },
      {
        title: Trans.effective_date,
        content:
          Trans.the_effective_date_of_this_privacy_policy_is_indicated_at_the_top_of_the_page,
      },
    ],
  },
  {
    title: Trans.contact_information,
    list: [
      {
        title: Trans.data_controller,
        content: Trans.your_company_name_and_address,
      },
      {
        title: Trans.data_protection_officer,
        content: Trans.name_and_contact_information_of_dpo,
      },
      {
        title: Trans.contact_us,
        content:
          Trans.if_you_have_questions_or_concerns_about_this_privacy_policy_or_your_data_please_contact_us_at,
      },
    ],
  },
  {
    title: Trans.additional_information_if_applicable,
    list: [
      {
        title: Trans.california_privacy_rights,
        content:
          Trans.if_you_are_a_california_resident_you_may_have_additional_rights_under_the_california_consumer_privacy_act_ccpa_please_see_our_separate_ccpa_privacy_notice_for_more_information,
      },
      {
        title: Trans.gdpr_data_subject_rights,
        content:
          Trans.if_you_are_an_eu_user_you_have_certain_rights_under_the_general_data_protection_regulation_gdpr_please_see_our_separate_gdpr_privacy_notice_for_more_information,
      },
    ],
  },
];

export const termsContent = [
  {
    title: Trans.introduction,
    content:
      Trans.thank_you_for_choosing_cx_genie_a_cutting_edge_software_as_a_service_saaS_platform_that_leverages_artificial_intelligence_to_automate_and_optimize_the_customer_support_process_cx_genie_is_designed_to_help_you_enhance_customer_engagement_with_minimal_effort_providing_you_with_a_powerful_tool_to_improve_your_customer_service_and_support_operations_please_read_these_terms_of_use_carefully_before_using_our_services,
  },
  {
    title: Trans.acceptance_of_terms,
    content:
      Trans.by_accessing_or_using_cx_genie_you_agree_to_be_bound_by_these_terms_of_use_if_you_do_not_agree_with_any_part_of_these_terms_please_do_not_use_our_services_these_terms_represent_a_legally_binding_agreement_between_you_and_cx_genie_so_it_s_important_to_understand_them_fully,
  },
  {
    title: Trans.changes_to_terms,
    content:
      Trans.cx_genie_reserves_the_right_to_modify_these_terms_of_use_at_any_time_we_will_notify_you_of_any_changes_by_posting_the_updated_terms_on_our_website_or_through_other_means_of_communication_it_is_your_responsibility_to_review_the_terms_periodically_and_your_continued_use_of_our_services_after_any_changes_constitutes_acceptance_of_those_changes,
  },
  {
    title: Trans.user_accounts,
    list: [
      {
        title: Trans.registration,
        content:
          Trans.to_use_cx_genie_you_may_be_required_to_create_a_user_account_you_agree_to_provide_accurate_complete_and_up_to_date_information_during_the_registration_process_you_are_solely_responsible_for_maintaining_the_confidentiality_of_your_account_credentials,
      },
      {
        title: Trans.access_control,
        content:
          Trans.you_are_responsible_for_all_activities_that_occur_under_your_account_you_agree_not_to_share_your_account_information_and_you_are_responsible_for_ensuring_that_no_unauthorized_person_gains_access_to_your_account,
      },
    ],
  },
  {
    title: Trans.use_of_cx_genie,
    list: [
      {
        title: Trans.permitted_use,
        content:
          Trans.you_may_use_cx_genie_for_its_intended_purpose_which_is_to_enhance_customer_support_and_engagement_this_includes_using_the_platform_s_ai_powered_features_and_tools_as_described_in_our_documentation,
      },
      {
        title: Trans.prohitted_activities,
        content:
          Trans.you_agree_not_to_engage_in_any_activities_that_are_illegal_harmful_or_violate_the_rights_of_others_while_using_cx_genie_this_includes_but_is_not_limited_to_spamming_distributing_malware_or_infringing_on_intellectual_property_rights,
      },
    ],
  },
  {
    title: Trans.subscription_and_payment,
    list: [
      {
        title: Trans.subscription_plans,
        content:
          Trans.cx_geni_offers_various_subscription_plans_each_with_its_own_features_and_pricing_by_subscribing_to_a_plan_you_agree_to_pay_the_associated_fees_as_described_on_our_website,
      },
      {
        title: Trans.billing,
        content:
          Trans.payments_are_processed_through_secure_third_party_payment_processors_you_agree_to_provide_accurate_billing_information_and_authorize_us_or_our_payment_processor_to_charge_your_chosen_payment_method,
      },
      {
        title: Trans.cancellation,
        content:
          Trans.you_can_cancel_your_subscription_at_any_time_and_your_access_to_cx_genie_will_continue_until_the_end_of_your_current_billing_cycle_no_refunds_will_be_provided_for_partial_subscription_periods,
      },
    ],
  },
  {
    title: Trans.privacy_and_data_security,
    content:
      Trans.cx_genie_takes_your_privacy_and_data_security_seriously_our_privacy_policy_outlines_how_we_collect_use_and_protect_your_personal_information_you_can_read_our_privacy_policy_to_understand_our_policies_in_detail,
  },
  {
    title: Trans.termination_of_service,
    content:
      Trans.cx_genie_reserves_the_right_to_terminate_or_suspend_your_access_to_our_services_at_our_discretion_without_prior_notice_for_violations_of_these_Terms_of_Use_or_any_other_reason_we_deem_appropriate,
  },
  {
    title: Trans.disclaimer_of_warranties,
    content:
      Trans.cx_genie_provides_its_services_as_is_and_as_available_we_make_no_warranties_express_or_implied_regarding_the_accuracy_reliability_or_availability_of_our_services_you_use_cx_genie_at_your_own_risk,
  },
  {
    title: Trans.limitation_of_liability,
    content:
      Trans.cx_genie_shall_not_be_liable_for_any_direct_or_indirect_damages_incidental_to_or_consequential_dames_whatsoever_in_connection_with_your_use_of_our_services_even_if_we_have_been_advised_of_the_possibility_of_such_damages,
  },
  {
    title: Trans.governing_law,
    content:
      Trans.these_terms_of_use_are_governed_by_and_confronted_in_accordance_with_the_laws_of_your_jurisdiction_without_regard_to_its_conflict_of_law_principles,
  },
  {
    title: Trans.contact_us,
    content:
      Trans.if_you_have_any_questions_concerns_or_feedback_regarding_these_terms_of_use_or_our_services_please_contact_us_at_hello_cxgenie_ai_by_using_cx_genie_you_acknowledge_that_you_have_read_understand_and_agree_to_these_terms_of_use_we_hope_you_find_cx_genie_to_be_a_valuable_tool_for_enhancing_your_customer_engagement_and_support_efforts,
  },
];

export const resourceNameByType: Record<ResourceTypes, string> = {
  [ResourceTypes.BLOGS]: Trans.blogs,
  [ResourceTypes.OLD_BLOGS]: Trans.blogs,
  [ResourceTypes.CASE_STUDY]: Trans.case_studies,
  [ResourceTypes.PRODUCT_VIDEOS]: Trans.product_videos,
  [ResourceTypes.RELEASES]: Trans.releases,
};

export const features = [
  {
    icon: "/features/feature_1_icon.svg",
    thumb: "/features/feature_1_thumb.png",
    title: Trans.ai_chatbots_title,
    description: Trans.ai_chatbots_desc,
    url: "/solutions/ai-chatbots",
  },
  {
    icon: "/features/feature_2_icon.svg",
    thumb: "/features/feature_2_thumb.svg",
    title: Trans.workflow_title,
    description: Trans.workflow_desc,
    url: "/solutions/workflow",
  },
  {
    icon: "/features/feature_3_icon.svg",
    thumb: "/features/feature_3_thumb.png",
    title: Trans.ticket_management_title,
    description: Trans.ticket_management_desc,
    url: "/solutions/ticket-management",
  },
  {
    icon: "/features/feature_4_icon.svg",
    thumb: "/features/feature_4_thumb.png",
    title: Trans.whitelabel_title,
    description: Trans.whitelabel_desc,
    url: "/solutions/white-label",
  },
  {
    icon: "/features/feature_5_icon.svg",
    thumb: "/features/feature_5_thumb.png",
    title: Trans.product_data_sync_title,
    description: Trans.product_data_sync_desc,
    url: "/solutions/product-data-sync",
  },
  {
    icon: "/features/feature_6_icon.svg",
    thumb: "/features/feature_6_thumb.png",
    title: Trans.help_desk_title,
    description: Trans.help_desk_desc,
    url: "/solutions/help-desk",
  },
];

export const keyFeatures = [
  {
    icon: "/svgs/key_feature_1.svg",
    name: Trans.key_feature_1_title,
    description: Trans.key_feature_1_desc,
  },
  {
    icon: "/svgs/key_feature_2.svg",
    name: Trans.key_feature_2_title,
    description: Trans.key_feature_2_desc,
  },
  {
    icon: "/svgs/key_feature_3.svg",
    name: Trans.key_feature_3_title,
    description: Trans.key_feature_3_desc,
  },
  {
    icon: "/svgs/key_feature_4.svg",
    name: Trans.key_feature_4_title,
    description: Trans.key_feature_4_desc,
  },
];

export const affiliateBenefits = [
  {
    icon: "/svgs/referral_benefit_1.svg",
    name: Trans.earn_high_commissions,
    description: Trans.earn_high_commissions_description,
  },
  {
    icon: "/svgs/referral_benefit_2.svg",
    name: Trans.offer_exclusive_discount,
    description: Trans.offer_exclusive_discount_description,
  },
  {
    icon: "/svgs/referral_benefit_3.svg",
    name: Trans.promote_cutting_edge_tools,
    description: Trans.promote_cutting_edge_tools_description,
  },
  {
    icon: "/svgs/referral_benefit_4.svg",
    name: Trans.flexible_and_supportive_program,
    description: Trans.flexible_and_supportive_program_description,
  },
];

export const audiences = [
  {
    name: Trans.customer_service_teams,
    description:
      Trans.strugging_with_high_contact_volumes_and_repetitive_inquiries_cx_genie_empowers_you_to_automate_faqs_offer_24_7_support_and_personalize_interactions_to_delight_customers_and_free_up_your_teams_time,
  },
  {
    name: Trans.e_commerce_and_retail_businesses,
    description:
      Trans.want_to_engage_customers_provide_real_time_assistance_and_offer_product_recommendations_cx_genie_helps_you_personalize_the_buying_journey_boost_sales_and_build_brand_loyalty_with_ai,
  },
  {
    name: Trans.marketing_and_sales_teams,
    description:
      Trans.looking_to_improve_lead_generation_qualify_prospects_nurture_relationships_cx_genie_lets_you_capture_valuable_customer_data_answer_questions_instantly_and_convert_leads_into_sales_with_interactive_chat_experiences,
  },
  {
    name: Trans.financial_services,
    description:
      Trans.need_to_provide_personalized_communication_offer_advice_and_streamline_account_management_cx_genie_helps_you_answer_customer_inquiries_24_7_address_concerns_and_build_trust,
  },
  {
    name: Trans.technology_and_software_startups,
    description:
      Trans.want_to_gather_product_feedback_prioritize_feature_requests_and_engage_directly_with_users_cx_genie_allows_you_to_collect_valuable_insights_automate_support_interactions_and_improve_your_product_roadmap,
  },
];

export const brands = [
  { name: "eastplayers", icon: "/svgs/brand_1.svg", width: 157 },
  { name: "agene", icon: "/svgs/brand_2.svg", width: 113 },
  { name: "your ai cheatsheet", icon: "/svgs/brand_3.svg", width: 128 },
  { name: "mfast", icon: "/svgs/brand_4.svg", width: 131 },
  { name: "anawan", icon: "/svgs/brand_5.svg", width: 194 },
  { name: "the frame", icon: "/svgs/brand_6.svg", width: 147 },
];

export const AppsumoTiers = [
  {
    name: Trans.appsumo_license_tire_1,
    price: "$216",
    discounted_price: "$59",
    max_monthly_conversation: 5000,
    max_document: 50,
    max_chatbot: 20,
    max_member: 5,
    tier: 1,
  },
  {
    name: Trans.appsumo_license_tire_2,
    price: "$264",
    discounted_price: "$149",
    max_monthly_conversation: 25000,
    max_document: -1,
    max_chatbot: 50,
    max_member: 20,
    tier: 2,
  },
  {
    name: Trans.appsumo_license_tire_3,
    price: "$480",
    discounted_price: "$289",
    max_monthly_conversation: 50000,
    max_document: -1,
    max_chatbot: -1,
    max_member: -1,
    tier: 3,
  },
];

export const appSumo = {
  name: Trans.life_time_deal,
  origin_price: "$69.0",
  discount_price: "$1,499",
  sub_name: Trans.special_offer_only_on,
  feature_1: [
    Trans["30000_messages_month"],
    Trans["50_documents_for_ai_knowledge_files_faqs_articles_urls"],
    Trans.install_chatbot_on_website_mobile_app,
    Trans.hybrid_chat_mode_for_agent_with_ai_recommendation,
  ],
  feature_2: [
    Trans.max_30_members,
    Trans.usage_reports,
    Trans.api_access,
    Trans.live_chat_technical_support,
    Trans.ai_model_openai_gpt4,
  ],
  terms: [
    Trans[
      "60_day_money_back_guarantee_try_it_out_for_2_months_to_make_sure_it_is_right_for_you"
    ],
    Trans.lifetime_access_to_cx_genie,
    Trans.all_future_updates,
    Trans.all_future_ai_model_updates,
    Trans.if_plan_name_changes_deal_will_be_mapped_to_the_new_plan_name_with_all_accompanied_updates,
    Trans.no_codes_no_stacking_just_choose_the_plan_that_s_right_for_you,
  ],
  conditions: [
    Trans.you_must_activate_your_plan_within_60_days_of_purchase,
    Trans.gdpr_compliant,
  ],
};

export const LanguageOptions = [
  {
    icon: "/svgs/flag_en.svg",
    value: "en",
    label: "English",
    shortLabel: "EN",
  },
  {
    icon: "/svgs/flag_vi.svg",
    value: "vi",
    label: "Vietnamese",
    shortLabel: "VI",
  },
];

export const Services = [
  {
    name: Trans.ai_agent_deployment_and_customization,
    content:
      Trans.we_set_up_and_fine_tune_ai_assistants_to_suit_your_requirements_ensuring_seamless_integration_with_your_existing_workflows,
    image: "/images/our_service_1.png",
  },
  {
    name: Trans.ai_application_development,
    content:
      Trans.our_team_crafts_specialized_applications_using_advanced_ai_technology_precisely_tailored_to_your_specifications_and_business_objectives,
    image: "/images/our_service_2.png",
  },
  {
    name: Trans.ai_consultation_services,
    content:
      Trans.our_expert_consultancy_provide_personalized_ai_consultation_sessions_to_help_you_navigate_the_complexities_of_ai_adoption_within_your_business_ensuring_you_make_informed_decisions_that_align_with_your_goals,
    image: "/images/our_service_3.png",
  },
];

export const WorkingProcessStages = [
  {
    name: Trans.deploy_cx_version_to_the_enterprise_server,
    contents: [
      Trans.clarify_requirements_and_customize_features_suitable_for_your_business,
      Trans.finalize_feature_updates_such_as_direct_database_connections_and_learning_from_real_conversations,
    ],
  },
  {
    name: Trans.data_analysis_and_core_ai_engine_development,
    contents: [
      Trans.utilize_client_data_for_ai_training_enriching_the_knowledge_base,
      Trans.develop_the_core_ai_engine_tailored_to_your_business_logic,
    ],
  },
  {
    name: Trans.implement_customization_features,
    contents: [
      Trans.create_and_implement_ai_applications_leading_to_the_core_ai_engine,
      Trans.seamless_integration_with_internal_systems,
      Trans.generate_comprehensive_performance_reports,
    ],
  },
  {
    name: Trans.guide_businesses_to_use_application_and_provide_ongoing_support,
    contents: [
      Trans.gather_weekly_for_feedback_sessions_continuous_enhancements_and_application_refinements,
    ],
  },
  {
    name: Trans.guide_businesses_to_use_application_and_provide_ongoing_support,
    contents: [
      Trans.provide_ongoing_maintenance_and_support_including_regular_updates_and_user_assistance,
    ],
  },
];

export const Features = [
  {
    name: Trans.proven_leaders,
    content:
      Trans.backed_by_a_team_of_builders_and_entrepreneurs_with_a_strong_track_record_of_success_in_tech_companies_,
    image: "/images/feature_1.png",
  },
  {
    name: Trans.ai_expertise,
    content:
      Trans.we_dont_just_provide_ai_we_deliver_a_competitive_advantage_our_custom_ai_solutions_drive_generative_impact_boost_revenue_and_position_you_ahead_of_your_competitors,
    image: "/images/feature_2.png",
  },
  {
    name: Trans.agile_communicative,
    content:
      Trans.were_flexible_collaborators_adapting_to_your_evolving_needs_and_ensuring_clear_communication_throughout_the_journey_you_ll_never_be_left_in_the_dark_with_the_cx_genie_team_by_your_side,
    image: "/images/feature_3.png",
  },
];

export const CaseStudies = [
  {
    image: "/images/case_study_1.png",
    name: Trans.enhanced_and_implemented_an_nlp_model_resulting_in_a_55_boost_in_auditor_efficiency,
    content:
      Trans.the_technical_team_had_reached_an_impasse_when_the_accuracy_of_their_mvp_model_leveled_off,
  },
  {
    image: "/images/case_study_2.png",
    name: Trans.implemented_automation_for_a_startup_s_due_pledge_procedure_resulting_in_a_25_improvement_in_efficiency,
    content:
      Trans.they_depended_on_a_manual_approach_for_gathering_security_survey_data,
  },
  {
    image: "/images/case_study_3.png",
    name: Trans.implemented_nlp_based_automation_for_a_security_compliance_startup_s_due_pledge_process,
    content:
      Trans.the_company_faced_challenges_in_automating_the_identification_of_business_expenses_and_categorizing_customer_transactions_diminishing_the_effectiveness_of_their_product_within_a_month,
  },
  {
    image: "/images/case_study_4.png",
    name: Trans.developed_and_implemented_a_strategy_to_launch_a_financial_platform_from_inception_to_initial_development,
    content:
      Trans.the_company_aimed_to_automate_a_labor_intensive_process_of_categorizing_transactions_as_either_personal_or_business_expenses_unfortunately_they_lacked_the_technical_proficiency_to_construct_and_deploy_a_tailored_machine_learning_architecture_and_model,
  },
];

export const discordBenefits = [
  {
    name: Trans.talk_to_devs,
    content: Trans.get_real_time_support_and_insights,
  },
  {
    name: Trans.shape_cx_genie,
    content: Trans.share_feedback_report_bugs_suggest_new_features,
  },
  {
    name: Trans.insider_knowledge,
    content: Trans.learn_tips_tricks_from_our_team_and_other_users,
  },
  {
    name: Trans.unlock_exclusives,
    content: Trans.free_add_ons_and_and_new_features_for_free,
  },
];

export const customerFeedbacks = [
  {
    avatar: "/images/client_1.png",
    name: "natasha00",
    title: "The more I train it, the better it gets. Highly recommend!",
    description:
      "CX Genie is beneficial! The real magic happens when you invest time building the chatbot's knowledge base. The more you feed it with answers to common questions, the better it responds to customer inquiries. This has significantly reduced my time on repetitive customer interactions, freeing me to focus on other issues. Highly recommend!",
    star: 5,
  },
  {
    avatar: "/images/client_2.png",
    name: "ljs.graphics1",
    title: "The mobile app and live chat is Amazing",
    description:
      "Overall, this tool is exceptionally strong. Its responsiveness is impressive, and the seamless transition to live chat handover enhances the user experience. The auto-training feature, leveraging previous conversations, is a standout feature, offering unparalleled efficiency.",
    star: 5,
  },
  {
    avatar: "/images/client_3.png",
    name: "jass_",
    title: "Great  product overall",
    description:
      "This tool is very good overall. Responds well, human handover is awesome, auto-training with previous conversations is fantastic.",
    star: 5,
  },
  {
    avatar: "/images/client_4.png",
    name: "seb_",
    title: "Works really great!",
    description:
      "I love how easy it was to set it up and train. Click here, click there - ready. Discord is alive and full of support from the CX Genie team.",
    star: 5,
  },
  {
    avatar: "/images/client_5.png",
    name: "Waldemar_Ramos",
    title:
      "This chatbot replaces more than 6 software and provides the entire customer service operation",
    description:
      "At the time of writing this review, the CX Genie needs some tweaking and fine-tuning, however, it has already proven to be a fantastic tool that will greatly help my operation.<br/><br/>I'm replacing several complex software and server expenses, such as: Flowise, Botpress, Typebot, N8N, VPS, GPT 4, among others, with just one software that does all this integrated and easy without any code.<br/><br/>Another important point for my evaluation is the attention and quick feedback given by the developers. The support is excellent and they welcome our suggestions for improvements. I recommend that they remain that way.<br/><br/>I'm excited about the upcoming product updates and see potential for it to be the best AI-powered LLM and support tool for sure.",
    star: 5,
  },
  {
    avatar: "/images/client_6.png",
    name: "apps007",
    title: "Lots of potential",
    description:
      "They seem to be focusing on functionality that a lot of bots haven't begun to think about as yet:<br/>1. Seamless integration between human agents and AI agents.<br/>2. Educating the AI based on human agents input.<br/>3. Workflows to enhance how the bot interacts with people.<br/><br/>There are still some bugs here and there but on the whole it's a usable product with a lot of potential.",
    star: 4,
  },
  {
    avatar: "/images/client_8.png",
    name: "Tony_Upbase",
    title: "Powerful AI assistant with mobile app & easy handover",
    description:
      "I purchased CX Genie, and overall, I think it's a well-rounded AI assistant platform.<br/><br/>The integration with Facebook Messenger is quick and easy. Customers can directly interact with the bot within Messenger. The CX Genie also has a mobile app which adds convenience, as the support team can receive notifications on the go.<br/><br/>The AI to Human handover feature is something I was looking for and here its done in a very simple way. CX Genie automatically sends a notification to a human agent when there is a complex question that it doesn't have the answer to, or a message flow can be created to trigger a human agent. Theres a smooth transition from AI to human assistance when needed.<br/><br/>Overall, CX Genie offers excellent value for its price, and I appreciate the ongoing updates and improvements from the CX Genie team.",
    star: 5,
  },
  {
    avatar: "/images/client_7.png",
    name: "onecup",
    title: "Amazing Platform",
    description:
      "This platform is a game-changer. It's incredibly well-designed, and it's evident that the developers know exactly what they're doing. I'm thrilled to have it.",
    star: 5,
  },
  {
    avatar: "/images/client_9.png",
    name: "info9927k",
    title: "Actively being developed",
    description:
      "CX Genie has a solid base and is being developed be a very good support team. They also take every bit of feedback on board in the discord channel. My only concern with the Tier 3 plan was the lack of white labelling for unlimited bots was pointless but Ethan assured me that they are going to review that decision at the end of June.",
    star: 5,
  },
  {
    avatar: "/images/client_10.png",
    name: "dinnoleon",
    title: "The Cochinita Pibil of chatbots",
    description:
      "5 tacos calientes for CX Genie. The feature set is extensive, and the interface is intuitive, so I had no trouble finding my way around and making good use of its capabilities. On top of that, CX Genie's analytics and insights have been crucial in empowering me to make data-driven decisions that have improved our customer experience. On the whole, CX Genie has exceeded my expectations, and I would suggest it to anybody seeking to improve their customer service processes.",
    star: 5,
  },
  {
    avatar: "/images/client_13.png",
    name: "Sebastian C",
    title: "Highly recommended product!",
    description:
      "We’ve successfully implemented CX Genie has become ouras our primary support solution for multiple products. It helped us solve around 60% of support cases without human assistance. The platform offers plenty of teaching methods, access to custom data sets, forms, and many other features that are constantly added and improved. Highly recommended product!",
    star: 5,
  },
  {
    avatar: "/images/client_14.png",
    name: "Viktor S",
    title: "You are my painkiller!",
    description:
      "It’s the exact functionality I’ve been looking for! You are my painkiller! Seriously, thank you and Product Hunt for matching us! I’ve been searching for months, and all other tools were okay, but they always missed something.",
    star: 5,
  },
];

export const lifetimeCustomerFeedbacks = [
  {
    avatar: "/images/client_1.png",
    name: "natasha00",
    title: "The more I train it, the better it gets. Highly recommend!",
    description:
      "CX Genie is beneficial! The real magic happens when you invest time building the chatbot's knowledge base. The more you feed it with answers to common questions, the better it responds to customer inquiries. This has significantly reduced my time on repetitive customer interactions, freeing me to focus on other issues. Highly recommend!",
    star: 5,
  },
  {
    avatar: "/images/client_5.png",
    name: "Waldemar_Ramos",
    title:
      "This chatbot replaces more than 6 software and provides the entire customer service operation",
    description:
      "At the time of writing this review, the CX Genie needs some tweaking and fine-tuning, however, it has already proven to be a fantastic tool that will greatly help my operation.<br/><br/>I'm replacing several complex software and server expenses, such as: Flowise, Botpress, Typebot, N8N, VPS, GPT 4, among others, with just one software that does all this integrated and easy without any code.<br/><br/>Another important point for my evaluation is the attention and quick feedback given by the developers. The support is excellent and they welcome our suggestions for improvements. I recommend that they remain that way.<br/><br/>I'm excited about the upcoming product updates and see potential for it to be the best AI-powered LLM and support tool for sure.",
    star: 5,
  },
  {
    avatar: "/images/client_1.png",
    name: "COO of MFast",
    title: "",
    description:
      "CX Genie has been a game-changer for us at MFast. With the AI-powered chatbots handling routine inquiries, we saw a 40% increase in team efficiency, allowing our agents to focus on more complex customer needs.\n\nIn just a short time, the chatbot served over 100,000 customers quickly and accurately, reducing wait times and improving the overall customer experience.",
    star: 5,
  },
  {
    avatar: "/images/client_8.png",
    name: "Tony_Upbase",
    title: "Powerful AI assistant with mobile app & easy handover",
    description:
      "I purchased CX Genie, and overall, I think it's a well-rounded AI assistant platform.<br/><br/>The integration with Facebook Messenger is quick and easy. Customers can directly interact with the bot within Messenger. The CX Genie also has a mobile app which adds convenience, as the support team can receive notifications on the go.<br/><br/>The AI to Human handover feature is something I was looking for and here its done in a very simple way. CX Genie automatically sends a notification to a human agent when there is a complex question that it doesn't have the answer to, or a message flow can be created to trigger a human agent. Theres a smooth transition from AI to human assistance when needed.<br/><br/>Overall, CX Genie offers excellent value for its price, and I appreciate the ongoing updates and improvements from the CX Genie team.",
    star: 5,
  },
  {
    avatar: "/images/client_13.png",
    name: "Sebastian C",
    title: "Highly recommended product!",
    description:
      "We’ve successfully implemented CX Genie has become ouras our primary support solution for multiple products. It helped us solve around 60% of support cases without human assistance. The platform offers plenty of teaching methods, access to custom data sets, forms, and many other features that are constantly added and improved. Highly recommended product!",
    star: 5,
  },
  {
    avatar: "/images/client_14.png",
    name: "Viktor S",
    title: "You are my painkiller!",
    description:
      "It’s the exact functionality I’ve been looking for! You are my painkiller! Seriously, thank you and Product Hunt for matching us! I’ve been searching for months, and all other tools were okay, but they always missed something.",
    star: 5,
  },
];

export const SolutionsHeader = [
  {
    icon: "chatbot",
    title: Trans.ai_chatbots_title,
    desc: Trans.ai_chatbots_desc,
    url: "/solutions/ai-chatbots",
    color: Colors.ACCENT_GREEN,
  },
  {
    icon: "user-square",
    title: Trans.help_desk_title,
    desc: Trans.help_desk_desc,
    url: "/solutions/help-desk",
    color: Colors.ACCENT_VIOLET,
  },
  {
    icon: "hierarchy-3",
    title: Trans.workflow_title,
    desc: Trans.workflow_desc,
    url: "/solutions/workflow",
    color: Colors.ACCENT_ORANGE,
  },
  {
    icon: "d-cube-scan",
    title: Trans.product_data_sync_title,
    desc: Trans.product_data_sync_desc,
    url: "/solutions/product-data-sync",
    color: Colors.ACCENT_PINK,
  },
  {
    icon: "tag",
    title: Trans.ticket_management_title,
    desc: Trans.ticket_management_desc,
    url: "/solutions/ticket-management",
    color: Colors.ACCENT_BLUE,
  },
  {
    icon: "global-edit",
    title: Trans.whitelabel_title,
    desc: Trans.whitelabel_desc,
    url: "/solutions/white-label",
    color: Colors.ACCENT_YELLOW,
  },
];

export const SolutionDetailData = {
  "ai-chatbots": {
    name: "AI Chatbots",
    hero: {
      tag: "AI Chatbots",
      title:
        "Revolutionize Customer Engagement with <strong>AI-Powered Chatbots</strong>",
      img: "/solutions/ai_chatbots_hero.png",
    },
    description: [
      "Enhance your customer's engagement and satisfaction with CX Genie's AI Chatbots. Our advanced AI technology allows you to automate responses, manage customer inquiries in real-time, and provide personalized support that feels human. Whether handling routine queries or complex issues, CX Genie's AI Chatbots ensure your customers receive accurate, timely responses, all while reducing your support team's workload.",
      "CX Genie expertly handles complex queries, asks clarifying questions, and engages in meaningful conversations with your customers. This reduces support volume, ensures 24/7 availability, and boosts customer satisfaction.",
    ],
    key_features: [
      {
        icon: "/solutions/ai_chatbots_feature_1.svg",
        name: "Intelligent Conversational AI",
        items: [
          "<strong>Human-Like Interactions:</strong> Engage your customers with natural, human-like conversations that feel more personal and less robotic. Our AI chatbots are designed to understand context and nuance, providing responses that resonate with your customers.",
          "<strong>Contextual Understanding:</strong> Leverage advanced natural language processing (NLP) to understand customer intent, ensuring that responses are relevant and accurate.",
        ],
      },
      {
        icon: "/solutions/ai_chatbots_feature_2.svg",
        name: "Multi-Source Responses",
        items: [
          "<strong>Knowledge Base Integration:</strong> Pull information from your existing knowledge base, including FAQs, documents, and other resources, to provide comprehensive answers to customer queries.",
          "<strong>External Data Access:</strong> Connect your chatbot to external databases and URLs to retrieve real-time information, ensuring that customers receive the most up-to-date responses.",
        ],
      },
      {
        icon: "/solutions/ai_chatbots_feature_3.svg",
        name: "Seamless Handoff",
        items: [
          "<strong>Hybrid Support Model:</strong> Automatically escalate complex issues to human agents when necessary, ensuring that customers always receive the best possible service.",
          "<strong>Integrated Support:</strong> Maintain a seamless transition between chatbot and human support, with full context passed on to live agents to avoid repetition and ensure continuity.",
        ],
      },
      {
        icon: "/solutions/ai_chatbots_feature_4.svg",
        name: "Continuous Improvement",
        items: [
          "<strong>Learning from Conversations:</strong> Monitor and analyze chatbot interactions to identify areas for improvement. Use this data to refine responses and improve the accuracy and effectiveness of your chatbot over time.",
          "<strong>Feedback Integration:</strong> Collect feedback directly from customers after chatbot interactions, allowing for ongoing optimization of your support processes.",
        ],
      },
      {
        icon: "/solutions/ai_chatbots_feature_5.svg",
        name: "Multilingual Capabilities",
        items: [
          "<strong>Global Reach:</strong> Support customers across the globe with automatic language detection and response in over 190 languages, ensuring clear communication regardless of location.",
          "<strong>Localized Responses:</strong> Customize responses to reflect local cultural nuances and preferences, enhancing the customer experience in different regions.",
        ],
      },
      {
        icon: "/solutions/ai_chatbots_feature_6.svg",
        name: "Omni-Channel Support",
        items: [
          "<strong>Unified Interaction:</strong> Deploy your chatbot across multiple channels, including your website, social media platforms, messaging apps, and more. Manage all interactions from a single dashboard, ensuring consistency and efficiency.",
          "<strong>Cross-Platform Integration:</strong> Seamlessly integrate your chatbot with popular platforms like Facebook Messenger, WhatsApp, Slack, and more, providing a unified support experience for your customers.",
        ],
      },
    ],
    show_meet_cx_genie: true,
    use_cases: [
      {
        title: "24/7 Customer Support",
        description:
          "Provide round-the-clock support without the need for a 24/7 team. Your AI chatbot can handle queries, resolve issues, and provide information anytime, anywhere.",
      },
      {
        title: "Lead Qualification",
        description:
          "Use AI chatbots to engage website visitors, qualify leads by asking key questions, and route them to the appropriate sales teams.",
      },
      {
        title: "E-commerce Assistance",
        description:
          "Assist customers in finding products, answering questions about availability, pricing, and shipping, and even guiding them through the checkout process.",
      },
    ],
  },
  workflow: {
    name: "Workflow",
    hero: {
      tag: "Workflow",
      title:
        "Streamline Your Customer Interactions with Dynamic <strong>Workflows</strong>",
      img: "/solutions/workflow_hero.png",
    },
    description: [
      "Empower your support team to deliver personalized, human-like customer interactions with CX Genie's Workflow feature. The intuitive Flow Builder allows you to design complex, branching conversation paths without writing a single line of code. Whether you're addressing common customer inquiries or managing complex, multi-step support processes, CX Genie's Workflow ensures your customers receive tailored, efficient support every time.",
    ],
    key_features: [
      {
        icon: "/solutions/workflow_feature_1.svg",
        name: "Visual Flow Builder",
        items: [
          "<strong>Easy-to-Use Interface:</strong> With a drag-and-drop interface, you can visually design conversation paths, making it easy to map out customer journeys without technical expertise.",
          "<strong>Dynamic Conversation Paths:</strong> Create branching logic that adapts to customer responses, ensuring that each interaction is relevant and personalized.",
        ],
      },
      {
        icon: "/solutions/workflow_feature_2.svg",
        name: "Personalized Responses",
        items: [
          "<strong>Conditional Logic:</strong> Use if-then conditions to customize responses based on customer inputs, allowing for a highly tailored support experience.",
          "<strong>Multi-Channel Support:</strong> Apply workflows across different channels (chatbots, email, social media) to maintain consistency in customer interactions.",
        ],
      },
      {
        icon: "/solutions/workflow_feature_3.svg",
        name: "Seamless Integration",
        items: [
          "<strong>Data-Driven Interactions:</strong> Integrate your workflows with your CRM and other tools to pull in customer data, creating a more personalized experience.",
          "<strong>Third-Party API Integration:</strong> Connect with external APIs to retrieve or send data during interactions, extending the functionality of your workflows.",
        ],
      },
      {
        icon: "/solutions/workflow_feature_4.svg",
        name: "Real-Time Feedback",
        items: [
          "<strong>Customer Satisfaction Surveys:</strong> Automatically trigger feedback forms at the end of interactions to gauge customer satisfaction and identify areas for improvement.",
          "<strong>Instant Alerts:</strong> Receive notifications if a customer interaction needs human intervention, ensuring that complex issues are promptly addressed.",
        ],
      },
      {
        icon: "/solutions/workflow_feature_5.svg",
        name: "Analytics & Insights",
        items: [
          "<strong>Detailed Reports:</strong> Track the performance of your workflows with metrics like resolution time, customer satisfaction scores, and more.",
          "<strong>Continuous Optimization:</strong> Use insights to refine conversation paths, ensuring that your workflows evolve with customer needs and business goals.",
        ],
      },
    ],
    show_meet_cx_genie: false,
    use_cases: [
      {
        title: "Customer Onboarding",
        description:
          "Guide new customers through the onboarding process, ensuring they receive all the necessary information and support.",
      },
      {
        title: "Lead Qualification",
        description:
          "Automate the lead qualification process by asking key questions and routing qualified leads to your sales team.",
      },
      {
        title: "Support Escalation",
        description:
          "Automatically escalate complex issues to human agents, ensuring that customers receive the best possible service.",
      },
    ],
  },
  "ticket-management": {
    name: "Ticket Management",
    hero: {
      tag: "Ticket Management",
      title:
        "Efficiently Handle Customer Queries with Advanced <strong>Ticket Management</strong>",
      img: "/solutions/ticket_hero.png",
    },
    description: [
      "Simplify and streamline your customer support processes. CX Genie's Ticket Management is designed to handle high volumes of customer inquiries with ease, our platform ensures no issue goes unresolved, enhancing your team's efficiency and your customers' satisfaction. Whether you're managing simple questions or complex issues, CX Genie's robust ticket management tools help you keep everything organized and on track.",
    ],
    key_features: [
      {
        icon: "/solutions/ticket_feature_1.svg",
        name: "Automated Ticket Routing",
        items: [
          "<strong>Smart Assignment:</strong> Automatically assign tickets to the most appropriate agents based on predefined rules such as skill sets, past performance, and current workload.",
          "<strong>Priority-Based Routing:</strong> Ensure that high-priority tickets are routed and addressed first, reducing the time to resolution for critical issues.",
        ],
      },
      {
        icon: "/solutions/ticket_feature_2.svg",
        name: "Personalized Responses",
        items: [
          "<strong>Comprehensive Overview:</strong> View all your customer interactions in one place, with easy filtering options to manage open, pending, and resolved tickets.",
          "<strong>Multi-Channel Integration:</strong> Consolidate tickets from various channels (email, chat, social media) into a single dashboard, ensuring nothing falls through the cracks.",
        ],
      },
      {
        icon: "/solutions/ticket_feature_3.svg",
        name: "Prioritization & SLAs",
        items: [
          "<strong>Customizable SLAs:</strong> Set and monitor service level agreements to ensure that your team meets performance targets and customer expectations.",
          "<strong>Escalation Policies:</strong> Automatically escalate tickets that are nearing their SLA deadlines to ensure timely resolution.",
        ],
      },
      {
        icon: "/solutions/ticket_feature_4.svg",
        name: "Collaborative Support",
        items: [
          "<strong>Team Collaboration:</strong> Enable multiple agents to work on a single ticket, share notes, and communicate internally to resolve issues faster.",
          "<strong>Customer Visibility:</strong> Keep customers informed about the status of their tickets with automated updates and transparent communication.",
        ],
      },
      {
        icon: "/solutions/ticket_feature_5.svg",
        name: "Customer Communication History",
        items: [
          "<strong>Contextual Support:</strong> Access a complete history of all interactions with a customer, ensuring continuity in support and more personalized service.",
          "<strong>Audit Trail:</strong> Maintain a detailed log of all actions taken on a ticket, providing transparency and accountability.",
        ],
      },
    ],
    show_meet_cx_genie: false,
    use_cases: [
      {
        title: "Incident Management",
        description:
          "Quickly address and resolve critical incidents by automatically routing them to specialized teams.",
      },
      {
        title: "Customer Feedback",
        description:
          "Capture and manage customer feedback through tickets, ensuring that issues are addressed and insights are used to improve service.",
      },
      {
        title: "Internal Support",
        description:
          "Manage internal IT or HR requests with the same efficiency as customer-facing tickets.",
      },
    ],
    ltd: {
      title: "Exclusive Lifetime Deal Pricing",
      subtitle:
        "Get lifetime access to a ticket management system that grows with your business. You can simplify your client support workflows with a solution thats always ready to deliver, no matter how your team scales.",
      price: "/solutions/ticket_ltd_price.svg",
      link: "https://buy.stripe.com/00g7uD7nV6o4gfe5kn",
      features: [
        {
          icon: "/solutions/ticket_ltd_1.svg",
          title: "One Payment, Forever Yours",
          desc: "No subscriptions, no hidden costs—enjoy seamless ticket management for life.",
        },
        {
          icon: "/solutions/ticket_ltd_2.svg",
          title: "Always Available",
          desc: "Whether you’re onboarding new team members or managing high ticket volumes, your tools are ready.",
        },
        {
          icon: "/solutions/ticket_ltd_3.svg",
          title: "Full Control",
          desc: "Tailor your ticketing experience to meet your team’s evolving needs without worrying about recurring fees. Scale with ease.",
        },
      ],
    },
  },
  "help-desk": {
    name: "Help Desk",
    hero: {
      tag: "Help Desk",
      title:
        "Elevate Your Customer Support with a Robust <strong>Help Desk System</strong>",
      img: "/solutions/help_desk_hero.png",
    },
    description: [
      "CX Genie's Help Desk solution is designed to streamline your support operations, enabling you to provide exceptional service while efficiently managing your support team.",
      "With tools to assign tasks, track performance, and facilitate seamless communication, our Help Desk platform ensures that your support efforts are always organized, efficient, and effective.",
    ],
    key_features: [
      {
        icon: "/solutions/help_desk_feature_1.svg",
        name: "Agent Management",
        items: [
          "<strong>Role-Based Access:</strong> Assign roles and permissions to agents, ensuring that each team member has the access they need to perform their job effectively.",
          "<strong>Performance Analytics:</strong> Track agent performance with detailed metrics, helping you identify top performers and areas for improvement.",
        ],
      },
      {
        icon: "/solutions/help_desk_feature_2.svg",
        name: "Task Assignment",
        items: [
          "<strong>Automated Task Distribution:</strong> Automatically assign tasks to agents based on availability, expertise, and current workload, ensuring optimal resource utilization.",
          "<strong>Manual Reassignment:</strong> Easily reassign tasks as needed to manage shifting priorities or agent availability.",
        ],
      },
      {
        icon: "/solutions/help_desk_feature_3.svg",
        name: "Comprehensive Ticket Tracking",
        items: [
          "<strong>Lifecycle Management:</strong> Track every ticket from creation to resolution, ensuring that nothing is missed and customers receive timely support.",
          "<strong>Real-Time Updates:</strong> Provide agents and customers with real-time updates on ticket status, ensuring transparency and reducing follow-up inquiries.",
        ],
      },
      {
        icon: "/solutions/help_desk_feature_4.svg",
        name: "Knowledge Base Integration",
        items: [
          "<strong>Instant Access:</strong> Equip your agents with a searchable knowledge base directly within the Help Desk interface, allowing them to quickly find and share accurate information.",
          "<strong>Continuous Learning:</strong> Automatically update the knowledge base with solutions from resolved tickets, ensuring that your team's collective knowledge is always growing.",
        ],
      },
      {
        icon: "/solutions/help_desk_feature_5.svg",
        name: "Customer Self-Service",
        items: [
          "<strong>Empowerment:</strong> Allow customers to resolve common issues on their own with a self-service portal, reducing the load on your support team.",
          "<strong>Ticket Deflection:</strong> Decrease the number of incoming tickets by providing customers with easy access to solutions for frequently asked questions.",
        ],
      },
    ],
    show_meet_cx_genie: false,
    use_cases: [
      {
        title: "Multi-Department Support",
        description:
          "Manage support requests across different departments (e.g., IT, HR, customer service) from a single platform.",
      },
      {
        title: "Customer Retention",
        description:
          "Improve customer retention by providing consistently high-quality support that addresses issues before they escalate.",
      },
      {
        title: "Proactive Support",
        description:
          "Use data from the Help Desk to identify recurring issues and proactively address them, reducing the number of incoming tickets.",
      },
    ],
    ltd: {
      title: "Exclusive Lifetime Deal Pricing",
      subtitle:
        "Simplify your customer support with the tools designed to track performance, boost efficiency and empower your team. Eliminate recurring costs while maintaining the exceptional service standards your clients deserve.",
      price: "/solutions/help_desk_ltd_price.svg",
      link: "https://buy.stripe.com/4gw4ir37FeUA0ggbIM",
      features: [
        {
          icon: "/solutions/help_desk_ltd_1.svg",
          title: "One-Time Payment, Lifetime Value",
          desc: "Never worry about monthly fees again—pay once and access forever.",
        },
        {
          icon: "/solutions/help_desk_ltd_2.svg",
          title: "Empower Your Team",
          desc: "Get access to advanced tools to track KPIs, monitor CSAT, and streamline operations effortlessly.",
        },
        {
          icon: "/solutions/help_desk_ltd_3.svg",
          title: "Scalable for the Future",
          desc: "As your support needs expand, your Help Desk tools will always keep up.",
        },
      ],
    },
  },
  "product-data-sync": {
    name: "Product Data Sync Up for Ecommerce",
    hero: {
      tag: "Product Data Sync Up for Ecommerce",
      title:
        "Keep Your Product Information Accurate with Seamless <strong>Data Sync</strong>",
      img: "/solutions/pds_hero.png",
    },
    description: [
      "In the fast-paced world of ecommerce, keeping your product information consistent across multiple platforms is crucial. With CX Genie's Product Data Sync Up feature, you can ensure that your product listings are always up-to-date, accurate, and synchronized across all your sales channels. This not only improves the customer experience but also reduces errors and administrative workload.",
    ],
    key_features: [
      {
        icon: "/solutions/pds_feature_1.svg",
        name: "Automatic Data Syncing",
        items: [
          "<strong>Real-Time Synchronization:</strong> Automatically sync your product data across all connected platforms, ensuring that any changes made in one place are instantly reflected everywhere.",
          "<strong>Error Reduction:</strong> Minimize the risk of errors in product information, such as pricing discrepancies or outdated stock levels, by maintaining consistent data across all channels.",
        ],
      },
      {
        icon: "/solutions/pds_feature_2.svg",
        name: "Cross-Platform Integration",
        items: [
          "<strong>Ecommerce Platform Support:</strong> Seamlessly integrate with leading ecommerce platforms like Shopify, BigCommerce, Magento, and more, ensuring your product data is always consistent.",
          "<strong>Custom Integration:</strong> Use our API to connect with any custom ecommerce platform or third-party tool, ensuring full flexibility and control over your data.",
        ],
      },
      {
        icon: "/solutions/pds_feature_3.svg",
        name: "Real-Time Updates",
        items: [
          "<strong>Instant Changes:</strong> Reflect any updates to your product catalog, such as new product launches or price changes, across all channels in real-time.",
          "<strong>Inventory Management:</strong> Automatically sync inventory levels, reducing the risk of overselling or stockouts and enhancing customer satisfaction.",
        ],
      },
      {
        icon: "/solutions/pds_feature_4.svg",
        name: "Centralized Management",
        items: [
          "<strong>Single Interface:</strong> Manage all your product data from a single, user-friendly interface, reducing the complexity of handling multiple platforms.",
          "<strong>Bulk Editing:</strong> Make bulk updates to product data across multiple platforms with ease, saving time and ensuring consistency.",
        ],
      },
      {
        icon: "/solutions/pds_feature_5.svg",
        name: "Data Accuracy Assurance",
        items: [
          "<strong>Audit Logs:</strong> Keep track of all changes made to your product data, providing a detailed history that helps ensure accuracy and compliance.",
          "<strong>Data Validation:</strong> Use built-in validation tools to check for inconsistencies or errors in your product data before it's synchronized, ensuring that only accurate information is published.",
        ],
      },
    ],
    show_meet_cx_genie: false,
    use_cases: [
      {
        title: "Multi-Channel Retailing",
        description:
          "For businesses selling across multiple platforms, CX Genie ensures that product information is consistent and accurate, enhancing the customer experience.",
      },
      {
        title: "Global Ecommerce Operations",
        description:
          "Manage product data for international markets with ease, ensuring that region-specific information (e.g., pricing, availability) is always up-to-date.",
      },
      {
        title: "Seasonal Product Launches",
        description:
          "Quickly update and synchronize seasonal product information across all sales channels, ensuring timely and accurate product launches.",
      },
    ],
    ltd: {
      title: "Exclusive Lifetime Deal Pricing",
      subtitle:
        "Easily connect your e-commerce platforms like Shopify, WooCommerce and others to CX Genie to enable automation and smarter customer support. Let your product data work seamlessly for you and your team.",
      price: "/solutions/pds_ltd_price.svg",
      link: "https://buy.stripe.com/7sI3enfUreUA1kkaEJ",
      special_title: "Why E-Commerce Love this LTD?",
      features: [
        {
          icon: "/solutions/pds_ltd_1.svg",
          title: "Effortless Integration",
          desc: "Sync your product data from your e-commerce platforms seamlessly with CX Genie to enhance your AI chatbot’s knowledge and accuracy.",
        },
        {
          icon: "/solutions/pds_ltd_2.svg",
          title: "Smarter Support, Always",
          desc: "Enable faster, more informed customer support with real-time product information at your team’s fingertips.",
        },
        {
          icon: "/solutions/pds_ltd_3.svg",
          title: "Pay Once, Grow Forever",
          desc: "Eliminate recurring costs and tedious manual updates with a one-time investment that powers continuous automation.",
        },
      ],
    },
  },
  "white-label": {
    name: "White label",
    hero: {
      tag: "White label for Agencies",
      title:
        "Seamless Branded Experience with <strong>Whitelabel Solutions</strong>",
      img: "/solutions/white_label_hero.png",
    },
    description: [
      "Your brand is your identity. With CX Genie's Whitelabel solution, you can deliver a fully branded customer support experience that aligns with your agency's image while leveraging the powerful features of our platform. Customize the look and feel of CX Genie to match your brand, and provide your clients with a cohesive, professional service that enhances their perception of your agency.",
    ],
    key_features: [
      {
        icon: "/solutions/white_label_feature_1.svg",
        name: "Custom Branding",
        items: [
          "<strong>Brand Identity:</strong> Customize the CX Genie interface with your agency's logo, colors, and branding elements, ensuring that your clients always see your brand first.",
          "<strong>White-Label Reports:</strong> Generate and deliver branded reports that showcase your agency's performance, helping to reinforce your value to clients.",
        ],
      },
      {
        icon: "/solutions/white_label_feature_2.svg",
        name: "Client-Specific Workspaces",
        items: [
          "<strong>Dedicated Workspaces:</strong> Create separate, secure workspaces for each of your clients, ensuring that their data is isolated and managed according to their unique needs.",
          "<strong>Custom Solutions:</strong> Tailor support solutions for each client, leveraging CX Genie's flexible features to meet their specific requirements.",
        ],
      },
      {
        icon: "/solutions/white_label_feature_3.svg",
        name: "Multi-Client Management",
        items: [
          "<strong>Centralized Dashboard:</strong> Manage all your client accounts from a single, easy-to-use dashboard, simplifying the process of overseeing multiple projects.",
          "<strong>Scalable Solutions:</strong> Easily scale your support operations as your agency grows, adding new clients and expanding services without compromising quality.",
        ],
      },
      {
        icon: "/solutions/white_label_feature_4.svg",
        name: "Flexible Customization",
        items: [
          "<strong>Feature Customization:</strong> Choose and customize the features that best serve each client's needs, ensuring that you deliver the most relevant solutions.",
          "<strong>API Access:</strong> Leverage CX Genie's API to create custom integrations that align with your client's existing systems and processes.",
        ],
      },
      {
        icon: "/solutions/white_label_feature_5.svg",
        name: "Client Reporting",
        items: [
          "<strong>Performance Analytics:</strong> Provide clients with detailed analytics and insights into their customer support performance, helping them understand the value of your services.",
          "<strong>Branded Dashboards:</strong> Offer clients access to their own branded dashboard, where they can monitor support metrics and track the effectiveness of the services you provide.",
        ],
      },
    ],
    show_meet_cx_genie: false,
    is_bright_color: true,
    use_cases: [
      {
        title: "Full-Service Agency",
        description:
          "Offer a comprehensive customer support solution as part of your full-service offerings, enhancing client satisfaction and loyalty.",
      },
      {
        title: "Niche Market Solutions",
        description:
          "Tailor CX Genie's features to serve specific industries or niches, allowing you to provide specialized support that meets the unique needs of your clients.",
      },
      {
        title: "Client Retention",
        description:
          "Strengthen client relationships by offering a branded, high-quality support solution that integrates seamlessly with their operations.",
      },
    ],
    ltd: {
      title: "Exclusive Lifetime Deal Pricing",
      subtitle:
        "Take your business or agency to the next level, and get lifetime access to fully customizable solutions that let you present CX Genie as your own branded platform. Impress clients, build loyalty, and add to your agency offering to create new revenue streams.",
      price: "/solutions/white_label_ltd_price.svg",
      link: "https://buy.stripe.com/8wM9CL8rZ7s81kk006",
      special_title: "Why Agencies Love the White Label LTD?",
      features: [
        {
          icon: "/solutions/white_label_ltd_1.svg",
          title: "Boost Client Trust",
          desc: "Deliver a seamless, branded experience by customizing dashboards, reports and more  to match the identity you need.",
        },
        {
          icon: "/solutions/white_label_ltd_2.svg",
          title: "Create New Revenue Streams",
          desc: "Offer branded CX Genie services to clients, adding value and generating recurring revenue for your agency.",
        },
        {
          icon: "/solutions/white_label_ltd_3.svg",
          title: "Pay Once, Grow Forever",
          desc: "Enjoy lifetime access to the White Label add-on with no monthly fees, letting you scale your offerings confidently.",
        },
      ],
    },
  },
};

export const lifetimeQuestions = [
  {
    question: Trans.is_the_lifetime_deal_a_one_time_payment,
    answer:
      Trans.yes_you_pay_once_for_lifetime_access_with_no_recurring_fees_this_includes_all_future_pro_plan_updates,
  },
  {
    question: Trans.what_happens_after_i_purchase_the_ltd,
    answer:
      Trans.youll_get_immediate_lifetime_access_to_cx_genie_and_all_future_pro_plan_updates_will_be_included,
  },
  {
    question: Trans.can_i_upgrade_or_downgrade_between_tiers,
    answer:
      Trans.yes_you_can_upgrade_or_downgrade_between_the_3_license_tiers_while_the_deal_is_active_and_within_60_days_of_purchase,
  },
  {
    question: Trans.is_there_a_refund_policy,
    answer:
      Trans.yes_you_have_a_60_day_money_back_guarantee_try_cx_genie_risk_free_for_2_months,
  },
  {
    question: Trans.does_the_lifetime_deal_include_future_updates,
    answer:
      Trans.absolutely_with_this_deal_you_get_lifetime_access_to_all_future_pro_plan_updates_and_features,
  },
  {
    question: Trans.do_i_need_any_technical_skills_to_set_up_and_use_cx_genie,
    answer:
      Trans.no_cx_genie_is_designed_to_be_user_friendly_and_requires_no_coding_or_technical_expertise_you_can_easily_set_up_ai_bots_workflows_and_integrations_in_minutes,
  },
  {
    question: Trans.what_platforms_does_cx_genie_integrate_with,
    answer:
      Trans.cx_genie_integrates_with_a_wide_range_of_platforms_including_cr_ms_social_media_apps_messaging_apps_and_more_were_also_always_looking_for_our_customers_to_tell_us_what_new_platforms_they_want_to_see,
  },
  {
    question: Trans.how_long_will_the_lifetime_deal_be_available,
    answer:
      Trans.this_is_a_limited_time_offer_and_wont_be_available_forever_be_sure_to_secure_your_lifetime_access_before_the_deal_expires,
  },
  {
    question: Trans.how_does_the_workflow_automation_feature_work,
    answer:
      Trans.cx_genie_s_workflow_automation_allows_you_to_set_up_triggers_and_actions_based_on_customer_or_agent_behavior_streamlining_your_support_processes_without_coding_you_can_automate_tasks_like_ticket_assignments_follow_ups_and_more,
  },
  {
    question: Trans.what_support_can_i_get,
    answer:
      Trans.all_cx_genie_customers_can_get_access_to_our_support_tram_via_discord_whether_you_need_help_setting_up_your_bots_or_optimizing_your_workflows_our_team_is_always_available_to_assist_you_or_you_can_schedule_a_live_demo_with_our_team,
  },
  {
    question: Trans.can_i_still_access_future_add_ons_and_features_with_the_ltd,
    answer:
      Trans.yes_your_lifetime_deal_includes_all_future_feature_updates_however_certain_add_ons_may_be_offered_as_separate_purchases,
  },
];
