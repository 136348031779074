import classNames from "classnames";
import { DetailedHTMLProps, FC, InputHTMLAttributes } from "react";
import styles from "./Input.module.scss";

interface InputProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "size"
  > {
  label?: string;
  error?: string | false;
  labelClassName?: string;
  containerClassName?: string;
  size?: "large" | "medium";
}

const Input: FC<InputProps> = ({
  label,
  className,
  error,
  labelClassName,
  containerClassName,
  size = "large",
  ...rest
}) => {
  return (
    <div className={classNames(styles.input_container, containerClassName)}>
      {label && (
        <label className={classNames(styles.label, labelClassName)}>
          {label}
        </label>
      )}
      <input
        className={classNames(styles.input, className, styles[size])}
        {...rest}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default Input;
